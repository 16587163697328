<template>
  <div id="student-component">
    <div id="searchCard" class="card rounded shadow-sm">
      <div class="card-body">
        <div id="filters" class="row">
          <div class="col-6">
            <label for="">Search by Name or School</label>
            <form @submit.prevent="searchStudent">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    id="empty-fields-button"
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="emptyFields"
                  >
                    <i class="bi bi-arrow-clockwise text-dark"></i>
                  </button>
                </div>

                <input
                  v-model="searchParameters.school_id"
                  type="search"
                  class="form-control"
                  placeholder="ID Number"
                />
                <input
                  v-model="searchParameters.lastname"
                  type="search"
                  class="form-control"
                  placeholder="Last Name"
                />

                <input
                  type="search"
                  v-model="searchParameters.firstname"
                  class="form-control"
                  placeholder="First Name"
                />

                <input
                  v-model="searchParameters.middlename"
                  type="search"
                  class="form-control"
                  placeholder="Middle Name"
                />

                <div class="input-group-append">
                  <button
                    id="name-search-button"
                    class="btn btn-outline-secondary text-dark"
                    type="submit"
                    :disabled="
                      !searchParameters.firstname &&
                        !searchParameters.lastname &&
                        !searchParameters.middlename &&
                        !searchParameters.school_id
                    "
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <!---------------------- FILTER DATA --------------------------->

          <div class="col-6">
            <label for="">Filter Data</label>
            <form @submit.prevent="getFilteredStudents">
              <div class="input-group">
                <select
                  v-model="filterParams.hei_code"
                  id="select-heis"
                  class="custom-select"
                  @change="getHeiPrograms"
                >
                  <option value="" selected disabled hidden>Choose HEI</option>
                  <option
                    v-for="hei in heis"
                    :key="hei.code"
                    :value="hei.code"
                    >{{ hei.name }}</option
                  >
                </select>

                <select
                  v-model="filterParams.program_id"
                  id=""
                  class="custom-select"
                >
                  <option value="" selected disabled hidden
                    >Choose a program </option
                  ><option
                    v-for="hei_program in hei_programs"
                    :key="hei_program.hei_program_id"
                    :value="hei_program.program_id"
                  >
                    {{ hei_program.program_name }}</option
                  >
                </select>

                <select
                  v-model="filterParams.year_level"
                  id=""
                  class="custom-select"
                >
                  <option value="" selected disabled
                    >Choose a year level</option
                  >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option value="">All</option>
                </select>
                <div class="input-group-append">
                  <button
                    id="filter-button"
                    class="btn btn-outline-secondary text-dark"
                    type="submit"
                    :disabled="
                      !filterParams.hei_code || !filterParams.program_id
                    "
                  >
                    <i class="bi bi-funnel text-dark"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <p class="mt-3 mb-0">Total Students: <b id="total-students"></b></p>
      </div>
    </div>

    <div v-show="showTable" class="card rounded shadow-sm">
      <br />
      <div class="">
        <div class="input-group col-3">
          <input
            v-model="searchValue"
            type="search"
            class="form-control "
            placeholder="Search here (Last Name, First Name, School ID)"
          />
        </div>
      </div>

      <div class="card-body">
        <table
          id="list-of-students-table"
          class="table table-bordered table-hover table-sm text-uppercase"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>SCHOOL ID</th>
              <th>LAST NAME</th>
              <th>FIRST NAME</th>
              <th>MIDDLE NAME</th>
              <th>HEI</th>
              <th>PROGRAM</th>
              <th>SEX</th>
              <th>YEAR LEVEL</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(student, index) in filteredStudents"
              :key="student.id"
              @click="viewStudentRecord(student, index)"
            >
              <th>{{ student.id }}</th>
              <td>{{ student.school_id }}</td>
              <td>{{ student.lastname }}</td>
              <td>{{ student.firstname }}</td>
              <td>{{ student.middlename }}</td>
              <td>{{ student.abbrev }}</td>
              <td>{{ student.program_name }}</td>
              <td>{{ student.sex }}</td>
              <td>{{ student.current_year_level }}</td>
            </tr>
          </tbody>
        </table>

        <div>
          <button
            id="scroll-to-top-btn"
            class="btn btn-primary btn-lg shadow d-none"
            style="position:fixed; bottom: 35px; right: 35px;"
            @click.prevent="scrollToTop"
          >
            <i class="bi bi-arrow-bar-up"></i>
          </button>
        </div>
      </div>
    </div>

    <div id="view-student-record-modal" class="modal" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <table
              id="student-information-table"
              class="table table-bordered table-sm"
            >
              <thead>
                <th colspan="4" class="text-center">STUDENT INFORMATION</th>
              </thead>
              <tbody>
                <tr>
                  <td>Student Name</td>
                  <td>
                    <b
                      >{{ form.lastname }}, {{ form.firstname }}
                      {{ form.middlename }}</b
                    >
                  </td>
                  <td>Year Level</td>
                  <td>
                    <b>{{ form.current_year_level }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Home Address</td>
                  <td>
                    <b>{{ form.address }}</b>
                  </td>
                  <td>Age</td>
                  <td><b>25</b></td>
                </tr>
                <tr>
                  <td>Program Name</td>
                  <td>
                    <b>{{ form.program_name }}</b>
                  </td>
                  <td>Sex</td>
                  <td>
                    <b>{{ form.sex }}</b>
                  </td>
                </tr>
                <tr>
                  <td>School</td>
                  <td>
                    <b>{{ form.name }}</b>
                  </td>
                  <td>ID No.</td>
                  <td>
                    <b>{{ form.school_id }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Special Order No</td>

                  <td>
                    <b v-if="form.count"
                      >({{ form.program_type }})(R-XIII)
                      {{ form.discipline_code }}-{{ form.count }}</b
                    >
                  </td>
                  <td>File</td>
                  <td>
                    <div class="custom-control custom-switch">
                      <input
                        v-model="showPDF"
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        :disabled="!form.count"
                      />
                      <label class="custom-control-label" for="customSwitch1"
                        >Show</label
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-between">
              <div class="my-auto">
                <div class="custom-control custom-checkbox mr-3">
                  <input
                    v-model="form.isReviewed"
                    type="checkbox"
                    class="custom-control-input"
                    @click="verifyStudent(form.id)"
                    id="marked-as-reviewed-checkbox"
                    disabled
                  />

                  <label
                    class="custom-control-label"
                    for="marked-as-reviewed-checkbox"
                    >Mark as Reviewed</label
                  >
                  <small
                    v-if="form.reviewed_by && form.reviewed_on"
                    class="form-text text-muted"
                    >By: {{ form.reviewed_by }} -
                    {{ form.reviewed_on | dateTime }}</small
                  >
                </div>
              </div>

              <div class="btn-group">
                <button
                  class="btn btn-outline-secondary text-dark btn-sm"
                  disabled
                >
                  <i class="bi bi-paperclip icon-sm mr-2"></i>Attach File
                </button>
                <button
                  class="btn btn-outline-secondary text-dark btn-sm"
                  @click.prevent="showMergeStudentCard"
                  disabled
                >
                  <i class="bi bi-arrows-collapse icon-sm mr-2"></i>Merge
                </button>
                <button
                  class="btn btn-outline-secondary text-dark btn-sm"
                  @click.prevent="showAddSemesterModal"
                  disabled
                >
                  <i class="bi bi-plus-circle icon-sm mr-2"></i>Add Semester
                </button>
                <button
                  class="btn btn-outline-secondary text-dark btn-sm"
                  disabled
                >
                  <i class="bi bi-pencil icon-sm mr-2"></i>Edit Information
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary text-dark btn-sm"
                  disabled
                >
                  <i class="bi bi-printer icon-sm mr-2"></i>Print CAV
                </button>
              </div>
            </div>
            <br />
            <div v-if="loader" class="text-center mb-2 mt-2">
              <div class="spinner-border text-secondary"></div>
              <p class="mb-0 mt-2">Please wait.</p>
              <p class="text-muted">
                The system is currently retrieving student records.
              </p>
            </div>

            <table
              v-show="showStudentRecord"
              id="student-records-table"
              class="table table-bordered table-sm compact"
            >
              <thead class="text-center">
                <th width="20%">COURSE CODE</th>
                <th width="45%">DESCRIPTIVE TITLE</th>
                <th width="10%">GRADE</th>
                <th width="10%">UNIT</th>
                <th width="15%">ACTION</th>
              </thead>
              <tbody id="tbody"></tbody>
            </table>

            <hr class="dotted" />

            <div class="mt-2">
              <span class="form-text text-muted"
                >*Last updated on: March 2, 2021</span
              >
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-link" data-dismiss="modal">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
    <!------------ End - View Student Record Modal ------------>
  </div>
</template>

<script>
export default {
  name: 'Students',
  data() {
    return {
      logs: '',
      reason: '',
      student: {
        id: '',
        school_id: '',
        fullname: '',
        address: '',
        courses: [],
        program: '',
      },
      studentNo: '',
      showMergeStudentResult: false,
      isChecked: false,
      mergeParams: {
        orig_student_id: '',
        dest_student_id: '',
      },
      loader: false,
      showMergeStudent: false,
      searchValue: '',
      showStudentRecord: false,
      semesters: '',
      academic_years: '',
      showPDF: false,
      currentTab: '',
      showTable: false,
      hei_programs: '',
      heis: [],
      filterParams: {
        hei_code: '',
        program_id: '',
        year_level: '',
        studentFilter: 1,
      },
      searchParameters: {
        school_id: '',
        lastname: '',
        firstname: '',
        middlename: '',
        studentFilter: 0,
      },
      students: '',
      records: '',
      form: new Form({
        id: '',
        school_id: '',
        lastname: '',
        firstname: '',
        middlename: '',
        birthday: '',
        sex: '',
        address: '',
        isGraduated: '',
        program_name: '',
        program_type: '',
        discipline_code: '',
        name: '',
        current_year_level: '',
        isReviewed: 0,
        reviewed_by: '',
        reviewed_on: '',
        isFinalized: 0,
        count: '',
        file: '',
        created_at: '',
        updated_at: '',
        index: '',
      }),
      semParams: {
        program_id: '',
        academic_year_id: '',
        semester_id: '',
        hei_code: '',
        year_level: '',
      },
    };
  },
  methods: {
    mergeStudent(student) {
      let l = document.getElementById('merge-student-btn');
      l.innerHTML = `Merging...`;
      l.disabled = true;

      axios
        .post('api/merge-student-record', {
          duplicatedStudentId: student.id,
          originalStudentId: this.form.id,
          fullName: student.fullname,
          reason: this.reason,
        })
        .then((res) => {
          l.innerHTML = 'Merge';
          l.disabled = false;
          this.showMergeStudentResult = false;
          toastr.success('Successfully merged student records', 'Information');
        })
        .catch((err) => {
          toastr.error('Something went wrong', 'Error');
        });
    },
    showMergeStudentCard() {
      this.showMergeStudent = true;
    },
    getStudentToMerge() {
      let l = document.getElementById('search-student-to-merge-btn');
      l.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .get('api/get-student-to-merge/' + this.studentNo)
        .then((res) => {
          this.student = res.data;
          this.showMergeStudentResult = true;
          l.innerHTML = 'Search student';
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
    getAllStudents() {
      let i = document.getElementById('total-students');
      i.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .get('api/students')
        .then((response) => {
          i.innerHTML = response.data;
        })
        .catch((error) => {
          i.innerHTML = 'error';
          toastr.error('Could not fetch total students', 'Error');
        });
    },
    deleteTableRow() {
      $('#new-semester-table tr:last').remove();
    },
    addTableRow() {
      $('#new-semester-table tbody:last-child').append(`
        <tr> 
          <td><input class="form-control"></td>
          <td><input class="form-control"></td>
          <td><input class="form-control"></td>
          <td><input class="form-control"></td>
        </tr>
      `);
    },
    addSemester() {
      let saveButton = document.getElementById('add-semester-button');
      saveButton.innerHTML =
        `<span class="spinner-border spinner-border-sm"></span>` +
        ' Inserting...';
      saveButton.disabled = true;

      var data = [];
      $('#add-semester-modal tbody tr').each(function() {
        var row_data = {
          course_code: '',
          course_description: '',
          grade: '',
          unit: '',
        };

        let input = $(this);
        row_data.course_code = input[0].children[0].children[0].value; //course_code
        row_data.course_description = input[0].children[1].children[0].value; //course_description
        row_data.grade = input[0].children[2].children[0].value; //grade
        row_data.unit = input[0].children[3].children[0].value; // unit

        data.push(row_data); //push to object to array
      });

      axios
        .post('api/courses', {
          params: {
            student_id: this.form.id,
            academic_year_id: this.semParams.academic_year_id,
            semester_id: this.semParams.semester_id,
            hei_code: this.semParams.hei_code,
            year_level: this.semParams.year_level,
            program_id: this.semParams.program_id,
          },
          data: data,
        })
        .then((response) => {
          $('#add-semester-modal').modal('hide');
          $('#view-student-record-modal').modal('show');
          document.getElementById('add-semester-form').reset();
          saveButton.innerHTML = 'Insert';

          saveButton.disabled = false;
          this.getStudentRecords(this.form.id);
          toastr.success('Added successfully.', 'Information');
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error!');
        });
    },
    showAddSemesterModal() {
      $('#add-semester-modal').modal('show');
      $('#view-student-record-modal').modal('hide');
    },
    verifyStudent(id) {
      //send request
      axios
        .put('api/student/' + id, status)
        .then((response) => {
          response.data
            ? toastr.success('Verified successfully.', 'Information')
            : toastr.warning('Unverified student.', 'Information');
          this.students[this.form.index]['isReviewed'] = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
    getFilteredStudents() {
      if (this.filterParams.hei_code == '') {
        toastr.error('No HEI selected.', 'Error');
        return;
      }

      let filterButton = document.getElementById('filter-button');
      filterButton.disabled = true;
      filterButton.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .post('api/student/fetch-record', this.filterParams)
        .then((response) => {
          filterButton.innerHTML = `<i class="bi bi-funnel"></i>`;
          filterButton.disabled = false;
          this.students = response.data;
          if (this.students.length != 0) {
            this.showTable = true;
          } else {
            toastr.error('No results found.', 'Information');
          }
        })
        .catch((error) => {
          toastr.error(error, 'Error');
        });
    },
    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.filterParams.hei_code)
        .then((response) => {
          this.hei_programs = response.data;
        })
        .catch((error) => {
          toastr.danger('Something went wrong', 'Request failed');
        });
    },
    emptyFields() {
      this.searchParameters.firstname = '';
      this.searchParameters.lastname = '';
      this.searchParameters.middlename = '';
    },

    getFilterParams() {
      /* let programs = 'api/programs';
      let heis = 'api/heis';

      const getPrograms = axios.get(programs);
      const getHeis = axios.get(heis); */

      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {});

      /*  axios
        .all([getPrograms, getHeis])
        .then(
          axios.spread((...responses) => {
            this.programs = responses[0].data;
            this.heis = responses[1].data;
          })
        )
        .catch((errors) => {
          alert(errors);
        }); */
    },

    searchStudent() {
      let searchButton = document.getElementById('name-search-button');
      searchButton.disabled = true;
      searchButton.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .post('api/student/fetch-record', this.searchParameters)
        .then((response) => {
          searchButton.innerHTML = `<i class="bi bi-search"></i>`;
          searchButton.disabled = false;
          this.students = response.data;

          if (this.students.length != 0) {
            this.showTable = true;
          } else {
            toastr.error('No results found.', 'Information');
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    viewStudentRecord(student, index) {
      student.index = index;
      $('#view-student-record-modal').modal('show');
      this.form.fill(student);
      this.getStudentRecords(student.id);
    },

    getStudentRecords(id) {
      this.loader = true;

      axios
        .get('api/courses/' + id)
        .then((response) => {
          this.showStudentRecord = true;
          this.records = response.data;
          this.loader = false;
          this.drawTable();
        })
        .catch((error) => {
          alert(error);
        });
    },

    drawTable() {
      let tbody = document.getElementById('tbody');

      try {
        for (let i = 0; i < this.records.length; i++) {
          let row = tbody.insertRow();
          row.classList.add('font-weight-bold');
          //row.classList.add('');
          let cell = row.insertCell();
          cell.innerHTML = `<center> * * ${this.records[i]['program_name']} * * <br> ${this.records[i]['semester']} - ${this.records[i]['academic_year']}  </center> `;
          cell.colSpan = 5;

          if (
            this.records[i]['course_codes'].length ===
              this.records[i]['course_description'].length &&
            this.records[i]['course_codes'].length ===
              this.records[i]['units'].length
          ) {
            var total_units = 0;
            for (let x = 0; x < this.records[i]['course_codes'].length; x++) {
              let course_row = tbody.insertRow();
              course_row.id = x;
              let course_code = course_row.insertCell();
              let course_desc = course_row.insertCell();
              let course_grades = course_row.insertCell();
              let course_units = course_row.insertCell();

              course_code.innerHTML = this.records[i]['course_codes'][x];
              course_code.id = this.records[i]['id'];
              course_code.name = this.records[i]['course_codes'][x];

              course_desc.innerHTML = this.records[i]['course_description'][x];
              if (this.records[i]['grades'] != null) {
                course_grades.innerHTML = this.records[i]['grades'][x];
              }
              course_units.innerHTML = this.records[i]['units'][x];
              total_units = total_units + Number(this.records[i]['units'][x]);
              // console.log(total_units);
              let actions = course_row.insertCell();
              actions.classList.add('text-center');
              actions.innerHTML =
                '<button class="btn btn-sm btn-link disabled"><i class="bi bi-pencil text-primary"></i></button><button class="btn btn-sm btn-link disabled"><i class="bi bi-trash text-danger"></i></button>';
            }

            let end_row = tbody.insertRow();
            let updated_at = end_row.insertCell();
            let updated_by = end_row.insertCell();
            let total_string = end_row.insertCell();
            let total_no = end_row.insertCell();
            let ref_no = end_row.insertCell();

            updated_at.innerHTML =
              '<span class="text-muted">Updated on: ' +
              moment(this.records[i]['updated_at']).format('l') +
              '</span>';
            updated_by.innerHTML =
              '<span class="text-muted">Uploaded by: ' +
              this.records[i]['updated_by'] +
              '</span>';
            total_string.innerHTML = '<span class="text-muted">Units:</span>';
            total_no.innerHTML =
              '<span class="text-muted">' + total_units + '</span>';
            ref_no.innerHTML =
              '<span class="text-muted">Ref No: ' +
              this.records[i]['id'] +
              '</span>';
          } else {
            throw 'There is a missing value!';
          }
        }

        //disable all buttons if isReviewed = true
        if (this.form.isReviewed) {
          $('#tbody button').attr('disabled', true);
        }
      } catch (err) {
        alert(err);
      }
    },
    getAcademicYears() {
      axios
        .get('api/academic-years')
        .then((response) => {
          this.academic_years = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
    getSemesters() {
      axios
        .get('api/semesters')
        .then((response) => {
          this.semesters = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
    handleScroll() {
      $(window).scrollTop() > 400
        ? $('#scroll-to-top-btn')
            .addClass('d-block')
            .removeClass('d-none')
        : $('#scroll-to-top-btn')
            .addClass('d-none')
            .removeClass('d-block');
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  computed: {
    filteredStudents() {
      let tempStudents = this.students;

      if (this.searchValue != '') {
        tempStudents = tempStudents.filter((student) => {
          return (
            student.lastname
              .toUpperCase()
              .includes(this.searchValue.toUpperCase()) ||
            student.firstname
              .toUpperCase()
              .includes(this.searchValue.toUpperCase()) ||
            student.school_id
              .toUpperCase()
              .includes(this.searchValue.toUpperCase())
          );
        });
      }

      return tempStudents;
    },

    isVerified() {
      return this.form.isReviewed == 0
        ? 'btn btn-outline-primary'
        : 'btn btn-danger';
    },
  },
  watch: {},
  mounted() {
    //get count of all students
    this.getAllStudents();

    //add event listener on scroll - back to top
    window.addEventListener('scroll', this.handleScroll);

    this.getSemesters();
    this.getAcademicYears();

    //register click event on students table

    //inline editing of student records

    $('#student-records-table').on('click', 'button', function(event) {
      //this.inlineEdit($(this).attr('id'));

      let row_id = $(this)
        .closest('tr')
        .attr('id');

      let values = [];
      let childrens = $('#' + row_id).children();

      for (let i = 0; i < 4; i++) {
        values.push(childrens[i].innerText);
      }

      let inputCourseCode = document.createElement('input');
      inputCourseCode.className = 'form-control';
      inputCourseCode.value = values[0];

      let inputCourseDesc = document.createElement('input');
      inputCourseDesc.className = 'form-control';
      inputCourseDesc.value = values[1];

      let inputGrade = document.createElement('input');
      inputGrade.className = 'form-control';
      inputGrade.value = values[2];

      let inputUnit = document.createElement('input');
      inputUnit.className = 'form-control';
      inputUnit.value = values[3];

      var calwin = 'Hello';

      childrens[0].innerHTML = '';
      childrens[0].appendChild(inputCourseCode);

      childrens[1].innerHTML = '';
      childrens[1].appendChild(inputCourseDesc);

      childrens[2].innerHTML = '';
      childrens[2].appendChild(inputGrade);

      childrens[3].innerHTML = '';
      childrens[3].appendChild(inputUnit);

      let saveButton = document.createElement('button');
      saveButton.className = 'btn btn-sm btn-outline-secondary ml-1';
      saveButton.id = 'save-changes-button';
      saveButton.innerHTML = '<i class="bi bi-check2 text-success"></i>';

      childrens[4].appendChild(saveButton);

      //---------------------- SAVE CHANGES ----------------

      $('#save-changes-button').on('click', function(e) {
        $('#save-changes-button').html(
          '<span class="spinner-border spinner-border-sm"></span>'
        );

        e.stopPropagation();
        let data_array = [];

        //fetch main tr id
        let row_id = $(this)
          .closest('tr')
          .attr('id');
        //fetch children of children [input, input, input, input]
        let values = $('#' + row_id)
          .children()
          .children();
        //fetch children of tr = [td,td,td,td]
        let children = $('#' + row_id).children();

        for (let i = 0; i < 4; i++) {
          data_array.push(values[i].value);
          children[i].innerHTML = data_array[i];
        }

        data_array.unshift(children[0].name); // store old item code

        // console.log(data_array);

        //send request to server to save changes

        axios
          .put('api/course/' + children[0].id, [data_array])
          .then((response) => {
            $('#save-changes-button').remove();
          })
          .catch((error) => {
            toastr.error(error.response.data.message, 'Error');
          });
      });
    });

    // --------------- MODAL CLOSING EVENT LISTENER -----------------------
    $('#view-student-record-modal').on('hidden.bs.modal', () => {
      $('#student-records-table tr').remove();
      this.showStudentRecord = false;
    });

    // ------------------ END MODAL CLOSING EVENT LISTENER ------------------

    this.getFilterParams();

    /*    $('#view-student-record-modal').on('hidden.bs.modal', function(e) {
      $('#mark-as-reviewed-button').html('');
    }); */
  },
  updated() {},
};
</script>

<style scoped>
#list-of-students-table tr {
  cursor: pointer !important;
}
</style>
